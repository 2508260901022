export const subjectCategories = {
  pre_school: "Pre School",
  todller: "Toddler",
  primary_education: "Primary Education",
  secondary_education: "Secondary Education",
  heigher_secondary_education: "Higher Secondary Education",
};

export const subjectTypes = {
  english: "English",
  hindi: "Hindi",
  maths: "Maths",
  science: "Science",
};

export const examTypes = {
  quaterly: "Quaterly Exam",
  annual: "Annual Exam",
  half_yearly: "Half Yearly Exam",
  pre_board: "Pre-Board Exam",
};

export const debitTransactionTypes = {
  generic_expense: "Generic Expense",
  rent: "Rent",
  student_materials: "Student Materials (Purchased)",
  maintenance_and_repairs: "Maintenance & Repairs",
  classroom_supplies: "Classroom Supplies",
  office_supplies: "Office Supplies",
  equipment_and_furniture: "Equipment & Furniture",
  staff_tranning: "Staff Tranning",
  promotions: "Marketing & Promotions",
  physical_utilities: "Physical Utilities",
  digital_utilities: "Digital Utilities",
  staff_student_allowances: "Staff & Student Allowances",
  activity_events: "Activity & Events (Cost)",
  miscellaneous_staff_expense: "Miscellaneous Staff Expense",
};

export const creditTransactionTypes = {
  generic_income: "Generic Income",
  student_materials: "Student Materials (Sold)",
  miscellaneous_student_fee: "Miscellaneous Student Fee",
  activity_events: "Activity & Events (Fee)",
  government_grants: "Government Grants",
  donations: "Donations",
};
