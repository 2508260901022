import { useTenantContext } from "src/contexts/tenant-context";

export const Logo = (props) => {
  const { tenantConfig } = useTenantContext();

  return (
    <img {...props} 
    src={tenantConfig?.logo? tenantConfig.logo : "/-192.png"} />
  );
};
