import { tokens } from "../tokens";

export const en = {
  [tokens.common.languageChanged]: "Language changed",
  [tokens.nav.academy]: "Academy",
  [tokens.nav.account]: "Account",
  [tokens.nav.analytics]: "Analytics",
  [tokens.nav.overview]: "Overview",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.pages]: "Pages",
  [tokens.nav.profile]: "Profile",
  [tokens.nav.register]: "Register",
  [tokens.nav.resetPassword]: "Reset Password",
  [tokens.nav.socialMedia]: "Social Media",
  [tokens.nav.verifyCode]: "Verify Code",
  [tokens.nav.classes]: "Classes",
  "nav.classes.num-3": "Class PG",
  "nav.classes.num-2": "Class Nursery",
  "nav.classes.num-1": "Class LKG",
  "nav.classes.num0": "Class UKG",
  "nav.classes.num1": "Class I",
  "nav.classes.num2": "Class II",
  "nav.classes.num3": "Class III",
  "nav.classes.num4": "Class IV",
  "nav.classes.num5": "Class V",
  "nav.classes.num6": "Class VI",
  "nav.classes.num7": "Class VII",
  "nav.classes.num8": "Class VIII",
  "nav.classes.num9": "Class IX",
  "nav.classes.num10": "Class X",
  "nav.classes.num11": "Class XI",
  "nav.classes.num12": "Class XII",
  "all": "All",
  addNewClass: "Add New Class",
  editClass: "Edit Class",
  teachers: "Teachers",
  teacher: "Teacher",
  students: "Students",
  student: "Student",
  attendance: "Attendance",
  finance: "Finance",
  examination: "Examination"
};
